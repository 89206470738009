// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>
// https://github.com/SortableJS/Sortable


import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  
  connect() {
    // listen for inputs
    this.startListening();
  }
  
  // connect() {
  //   this.inputElement = this.element.querySelector("#hidden-input");
  //   this.inputElement.focus();
  // }
  
  
  startListening() {
    // alert("start listening")
	  
    // reset
    this.reset();
	
	// keydown listener
    this.element.addEventListener('keydown', this.keydownListener.bind(this));
  }
  
  
  reset() {
    // alert("reset")
    this.keys = '';
    this.timer = null;
  }
  
  
  
  keydownListener(event) {
    
    clearTimeout(this.timer);

    this.keys += event.key;

    this.timer = setTimeout(() => {
		
      // alert(this.keys)
		
	  this.keys = this.keys.replace(/shift/g, "");
	  this.keys = this.keys.replace(/Shift/g, "");
	  this.keys = this.keys.replace(/enter/g, "");
	  this.keys = this.keys.replace(/Enter/g, "");
	  
	  
	  // alert(this.keys)
      
	  if (this.isValidURL(this.keys) && this.isBarcodeInput(this.keys)) {
	    
        // alert("redirect")
		console.log("Success.")
        window.location.href = this.keys;
		
      } else {
       
	    console.log("Not a url and barcode: " + this.keys)
	    // alert(this.keys)
	    // window.location.href = this.keys;
      	
      }
      
	  this.reset();
	  
    }, 200); // Wait for 100ms after last keydown event before processing the input
  }
  
  
  isBarcodeInput(input) {
    // Input starts with http or https
    const startsWithHttp = input.startsWith('http://') || input.startsWith('https://');

    // Input length is more than 20 characters
    const isLongEnough = input.length > 20;

    // Adjust these conditions based on your requirements
    return startsWithHttp && isLongEnough;
  }
	
	
  isValidURL(str) {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
  }
  
  
}
