import BulkController from "../controllers/bulk_controller"

export default class extends BulkController {

	destroy(event) {
	    
		event.preventDefault()

		let data = new FormData()
		// if (this.selected.length == this.checkboxTargets.length) {
		//   data.append("all", true)
		// } else {
		//   this.selected.forEach((checkbox) => data.append("ids[]", checkbox.value))
		// }
		
		this.selected.forEach((checkbox) => data.append("ids[]", checkbox.value))

		// Rails.ajax({
		//   url: "/items/bulk",
		//   type: "DELETE",
		//   data: data
		// })
		
		let token = document.querySelector('meta[name="csrf-token"]').content;
		fetch("/items/bulk", {
			method: "DELETE",
			headers: {
				"X-CSRF-Token": token,
			},
			body: data,
		}).then((response) => {
			if (response.redirected) {
				window.location.href = response.url;
			}
		});
		
	}
	
	print(event) {
	    
		event.preventDefault()

		let data = new FormData()
		// if (this.selected.length == this.checkboxTargets.length) {
		//   data.append("all", true)
		// } else {
		//   this.selected.forEach((checkbox) => data.append("ids[]", checkbox.value))
		// }
		
		this.selected.forEach((checkbox) => data.append("ids[]", checkbox.value))

		const queryString = new URLSearchParams(data).toString();
		window.location.href = "/items/print?" + queryString;
		
	}
	
	
	email(event) {
	    
		event.preventDefault()

		let data = new FormData()
		// if (this.selected.length == this.checkboxTargets.length) {
		//   data.append("all", true)
		// } else {
		//   this.selected.forEach((checkbox) => data.append("ids[]", checkbox.value))
		// }
		
		this.selected.forEach((checkbox) => data.append("ids[]", checkbox.value))
		
		const queryString = new URLSearchParams(data).toString();
		window.location.href = "/emails/new?model=Item&" + queryString;
		
	}
	
	draft_email(event) {
	    
		event.preventDefault()

		let data = new FormData()
		// if (this.selected.length == this.checkboxTargets.length) {
		//   data.append("all", true)
		// } else {
		//   this.selected.forEach((checkbox) => data.append("ids[]", checkbox.value))
		// }
		
		this.selected.forEach((checkbox) => data.append("ids[]", checkbox.value))
		
		const queryString = new URLSearchParams(data).toString();
		window.location.href = "/draft_emails/new?model=Item&" + queryString;
		
	}

}