import { Controller } from "@hotwired/stimulus"
import SlimSelect from 'slim-select'

export default class extends Controller {
	
  static targets = [ "item_id", "item_details", "item_category_id", "rate_id", "location_id", "payment_details", "payment_amount", "payment_discount", "available_spaces", "button", "waitlist", "waitlist_box", "start_date", "end_date", "date_inputs", "discount_box", "payment_subtotal_amount", "payment_total_amount", "duration_in_days", "display_start_date", "display_end_date" ]

  connect() {
	this.category_changed()
	this.item_changed()
	this.rate_changed()
	  
  	  // this.allergenTarget = 
	  new SlimSelect({
  		select: '#booking_item_category_id',
  	  })
	  
	  new SlimSelect({
  		select: '#booking_item_id',
  	  })
  }
  
  category_changed(event) {
	
	// event.preventDefault()

	// When: item_category_id changed
	let item_category_id = this.item_category_idTarget.value
	// const item_category_id = this.targets.find("item_category_id").value;
	
	let location_id = this.location_idTarget.value
	
	
	Rails.ajax({
		type: "GET",
		url: "/parking/"+ location_id +"/get_items.json",
		data: "item_category_id=" + item_category_id,
		success: (data) => {
		  this.updateItemDropdown(data);
		},
	});
	
	
	// const item_category_id = this.targets.find("item_category_id").value;
	Rails.ajax({
		type: "GET",
		url: "/parking/"+ location_id +"/get_rates.json",
		data: "item_category_id=" + item_category_id,
		success: (data) => {
		  this.updateRateDropdown(data);
		  
		  this.rate_changed();
		  
		},
	});
	
  }
  
  updateItemDropdown(data) {
	
	this.item_idTarget.innerHTML = "";
	
	const num_rates = data;
	
	if (num_rates == 0) {
		const option = document.createElement("option");
		option.value = "";
		option.innerHTML = "I'm parking something new...";
		this.item_idTarget.appendChild(option);
	} else {
		
		const option = document.createElement("option");
		option.value = "";
		option.innerHTML = "Parking new item...";
		this.item_idTarget.appendChild(option);
		
		data.forEach((item) => {
			const option = document.createElement("option");
			option.value = item.id;
			option.innerHTML = item.serial_number;
			this.item_idTarget.appendChild(option);
		});
		
		// set selected value
		if( this.item_idTarget.dataset.selecteditem !== undefined ){
			this.item_idTarget.value = this.item_idTarget.dataset.selecteditem;
		}
		
	}
	
	this.item_changed()
	
  }
  
  updateRateDropdown(data) {
	
	this.rate_idTarget.innerHTML = "";
	
	const num_rates = data;
	
	if (num_rates == 0) {
		const option = document.createElement("option");
		option.innerHTML = "No rates. Parking currently unavailable for this category.";
		this.rate_idTarget.appendChild(option);
	} else {
		data.forEach((rate) => {
			const option = document.createElement("option");
			option.value = rate.id;
			option.innerHTML = rate.name;
			this.rate_idTarget.appendChild(option);
		});
		
		// set selected value
		if( this.rate_idTarget.dataset.selecteditem !== undefined ){
			this.rate_idTarget.value = this.rate_idTarget.dataset.selecteditem;
		}
		
			
	}
	
  }
  
  item_changed(event) {
	
	// event.preventDefault()
	
	// When item_id changed
	let item_id_value = this.item_idTarget.value
	if (item_id_value == "") {
		this.item_detailsTarget.classList.remove("hidden");
	} else {
		this.item_detailsTarget.classList.add("hidden");
	}
	
  }
  
  rate_changed(event) {
	
	// event.preventDefault()  
	
	let location_id = this.location_idTarget.value  
	let rate_id_value = this.rate_idTarget.value
	  
	  
	let start_date_value = this.start_dateTarget.value
	let end_date_value = this.end_dateTarget.value
	
	// When: rate_id changed and customer chooses the space (NOT NEEDED YET)
	// When: rate_id changed display payment amount
	
  	Rails.ajax({
  		type: "GET",
  		url: "/parking/"+ location_id +"/get_rate.json",
  		data: "rate_id=" + rate_id_value + "&start_date=" + start_date_value + "&end_date=" + end_date_value,
		// data: "rate_id=" + rate_id_value,
  		success: (data) => {
  		  
		  // this.updateRateDropdown(data);
		  
		  //this.payment_amountTarget.innerHTML = JSON.stringify(data);
		  
		  // Display/Hide Stripe Elements
  	  	  if (data && data.price > 0) {

  			  // Display Rate Price
  			  // data.name
  			  
			  
			  this.available_spacesTarget.innerHTML = data.available_spaces;
			  
			  // this.payment_discountTarget.innerHTML = data.percentage_discount;
			  this.payment_discountTarget.innerHTML = data.percentage_discount;
			  
			  let discount_amount = data.total_price * data.percentage_discount * 0.01;
			  
			  // this.payment_discountTarget.innerHTML = data.percentage_discount + "% : -€" + data.price * data.percentage_discount * 0.01 ;
			  
			  // this.duration_in_daysTarget.innerHTML = data.duration_in_days;
			  this.duration_in_daysTarget.innerHTML = `${data.duration_in_days} days`;
			  
			  
			  this.payment_subtotal_amountTarget.innerHTML = data.total_price;
			  
			  this.payment_total_amountTarget.innerHTML = data.total_price - discount_amount;
			  
			  
			  if (data.daily_rate == true) {
				 // show date inputs
			 	 this.date_inputsTarget.classList.remove("hidden");
				 this.discount_boxTarget.classList.remove("hidden");
				 
				 // this.payment_amountTarget.innerHTML = data.price + "/day";
				 
				 this.payment_amountTarget.innerHTML = `${data.price}/day`;
				 
				 this.display_start_dateTarget.innerHTML = new Date(start_date_value).toLocaleDateString('en-us', { weekday:"short", year:"numeric", month:"short", day:"numeric"}) ;
				 this.display_end_dateTarget.innerHTML = new Date(end_date_value).toLocaleDateString('en-us', { weekday:"short", year:"numeric", month:"short", day:"numeric"}) ;
				 
			  } else {
			  	 // hide date inputs and display rate start and end dates
			  	 this.date_inputsTarget.classList.add("hidden");
				 this.discount_boxTarget.classList.add("hidden");
				 
				 this.payment_amountTarget.innerHTML = `${data.price} set fee`;
				 
				 
				 this.display_start_dateTarget.innerHTML = new Date(data.start_date).toLocaleDateString('en-us', { weekday:"short", year:"numeric", month:"short", day:"numeric"}) ;
				 this.display_end_dateTarget.innerHTML = new Date(data.end_date).toLocaleDateString('en-us', { weekday:"short", year:"numeric", month:"short", day:"numeric"}) ;
			  }
			  
			  
			  
			  if (data.available_spaces > 0) {
			 	 this.payment_detailsTarget.classList.remove("hidden");
				 //this.buttonTarget.innerHTML = "Pay Now";
				 this.buttonTarget.innerHTML = "Book Now";
				 //this.waitlist_boxTarget.checked = false;
				 this.waitlist_boxTarget.value = false;
				 
				 // this.waitlistTarget.classList.add("hidden");
				 this.waitlistTargets.forEach(target => target.classList.add("hidden"))
				 
			  } else {
			  	 this.payment_detailsTarget.classList.add("hidden");
				 this.buttonTarget.innerHTML = "Join Waiting List";
				 // this.waitlist_boxTarget.checked = true;
				 this.waitlist_boxTarget.value = true;
				 
				 // this.waitlistTarget.classList.remove("hidden");
				 this.waitlistTargets.forEach(target => target.classList.remove("hidden"))
			  }
			  
  			  
  	  	  } else {
  			  this.payment_amountTarget.innerHTML = "";
			  this.payment_total_amountTarget.innerHTML = "";
			  this.available_spacesTarget.innerHTML = "";
  			  this.payment_detailsTarget.classList.add("hidden");
  	  	  }
		  
		  // load spaces available for this rate
		  
		  
		  
  		},
  	});
	
  }
   
}