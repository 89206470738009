import { Controller } from "@hotwired/stimulus"
import SlimSelect from 'slim-select'

export default class extends Controller {
	
  // static targets = [ ]

  connect() {
	  
  	  // this.allergenTarget = 
	  // new SlimSelect({
	  //   		select: '#booking_item_category_id',
	  //   	  })
	  
	  new SlimSelect({
  		select: '#booking_item_id',
  	  })
  }

}